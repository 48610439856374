/* src/components/Gallery.css */
.gallery {
  justify-content: center;
  text-align: center;
}
/* @media only screen and (max-width: 600px) {
  .gallery {
    margin-left: 17%;
  }
} */
