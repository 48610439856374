/* src/App.css */

.App {
  text-align: center;
  background-image: url("../src/images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box {
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 70%;
  margin: 0 auto;
  /* background-color: #55555533; */
}

.box-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.box-paragraph {
  text-align: left !important;
  font-size: 1rem;
  color: #000000;
  font-family: sans-serif;
}
.bio-img {
  width: 70%;
}
@media only screen and (max-width: 600px) {
  .box-paragraph {
    font-size: 0.5rem;
  }
  .bio-img {
    width: 80%;
    margin-top: 2rem;
  }
  .box {
    padding-top: 0px;
    width: 80%;
  }
  .box-paragraph {
    font-size: 0.7rem;
  }
}
