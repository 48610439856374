/* src/components/ArtPiece.css */
.art-piece {
  text-align: center;
}

.art-piece img {
  height: 24rem;
}

.art-piece h2 {
  font-size: 1em;
}

.art-piece p {
  font-size: 1em;
}
@media only screen and (max-width: 600px) {
  .art-piece img {
    height: 7rem;
  }
  .art-piece h2 {
    font-size: 0.5em;
  }
  .art-piece p {
    font-size: 0.5em;
  }
}
